import _ from 'lodash';
import React from 'react';
import {Link} from "react-router-dom";
import {Card, Col, Row} from "react-bootstrap";

export default function GridView({items, language}) {

    const rows = _.chunk(items, 2);

    return rows.map(cols => {
        return <Row key={cols[0].id} xs={2} md={3} lg={4} xl={5} xxl={6} className="g-1">
            {cols.map(item => {
                return <Col key={item.id} style={{padding: '0.1rem'}}>
                    <Link to={`/item/${item.id}`} style={{color: 'white', textDecoration: 'none'}}>
                        <Card className="grid-card h-100" style={{background: 'rgb(0, 57, 132)'}}>
                            {item.mediaFiles &&
                                item.mediaFiles.slice(0, 1).map(({ id, _links: { download: { href } }, filename }) =>
                                <Card.Img className="grid-img" key={id} src={href} alt={filename} />)}
                            <Card.Body style={{background: 'rgb(0, 57, 132)'}}>
                                <Card.Subtitle style={{textAlign: 'center'}}>
                                    {item.titles[language]}
                                </Card.Subtitle>
                                <br />
                                <Card.Text style={{fontSize: '10pt'}}>
                                    ({item.exhibitionNumber}) {item.descriptions[language].substring(0, 100)}
                                    {'... '}
                                    <Link to={`/item/${item.id}`} style={{color: '#D4AF37', fontWeight: 'bold'}}>Mehr</Link>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>;
            })}
        </Row>;
    });
}