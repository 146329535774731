import React from 'react';
import {Link} from "react-router-dom";
import {Card, Carousel} from "react-bootstrap";

export default function ListView({items, language}) {
    return items.map(item =>
        <Card key={item.id} style={{width: '80%', margin: '0.5rem', padding: '0.25rem'}}>
            <Carousel>
                {item.mediaFiles && item.mediaFiles.map(({ id, _links: { download: { href }, item: { href: itemHref } }, filename }) => {
                    const mediaItemId = itemHref.substr(itemHref.lastIndexOf('/'));
                    return <Carousel.Item key={id}>
                        <a href={`/#/item${mediaItemId}`}>
                            <Card.Img src={href} alt={filename} className="list-view-img" />
                        </a>
                    </Carousel.Item>;
                })}
            </Carousel>
            <Card.Body style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', padding: 0}}>
                <div style={{background: 'rgb(0, 57, 132)', padding: '0.25rem'}}>
                    <Card.Title>
                        <Link to={`/item/${item.id}`} style={{color: 'white', fontWeight: 'bold'}}>
                            {item.titles[language]} ({item.exhibitionNumber})
                        </Link>
                    </Card.Title>
                    <Card.Text>
                        {item.descriptions[language].substring(0, 200)}
                        {'... '}
                        <Link to={`/item/${item.id}`} style={{color: '#D4AF37', fontWeight: 'bold'}}>Mehr</Link>
                    </Card.Text>
                </div>
            </Card.Body>
        </Card>);
}