import React, {useMemo, useState} from 'react';
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {BsFillGridFill, BsCardText} from 'react-icons/bs';
import GridView from "../components/GridView";
import ListView from "../components/ListView";
import ActionBar from "../components/ActionBar";
import {useNavigate, useSearchParams} from "react-router-dom";

function ItemsList() {

    const [items, setItems] = useState([]);
    useMemo(async () => {
        const response = await fetch(`${process.env.REACT_APP_API || window.location.origin}/api/items/search/findByActiveIsTrueOrderByOrderNumberAsc`);
        if(response.status === 200) {
            const {_embedded: {items}} = await response.json();
            setItems(items);
        }
    }, [setItems]);

    const [language, setLanguage] = useState('de');
    const [searchParams] = useSearchParams();
    const [displayMode, setDisplayMode] = useState(searchParams.get('v') || 'list');
    const nav = useNavigate();

    return <main  style={{color: '#FFFAE5', fontFamily: "'Roboto',Helvetica,Arial,Lucida,sans-serif"}}>
        <header style={{display: 'flex', justifyContent: 'center', padding: '0.5rem'}}>
            <a href="http://sam-wien.at/"><img src="/logo_blue_5.png" alt="SAM" /></a>
        </header>
        <article style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <ActionBar language={language} setLanguage={setLanguage} />
            <ToggleButtonGroup type="radio" name="displayMode" value={displayMode} onChange={value => {
                setDisplayMode(value);
                nav(`/?v=${value}`);
            }} style={{margin: '0.25rem'}}>
                <ToggleButton id="list" value="list"><BsCardText /></ToggleButton>
                <ToggleButton id="grid" value="grid"><BsFillGridFill /></ToggleButton>
            </ToggleButtonGroup>
            {displayMode === 'list' && <ListView items={items.filter(({viewOption}) => viewOption !== 'Grid')} language={language} />}
            {displayMode === 'grid' && <GridView items={items.filter(({viewOption}) => viewOption !== 'List')} language={language} />}
        </article>
    </main>;
}

export default ItemsList;