import React from 'react';
// components
import {Carousel} from "react-bootstrap";

export default function MediaFileCarousel({mediaFiles = [], loading = false}) {
    return <Carousel style={{height: '30vh'}}>
        {loading && <Carousel.Item>
            <img className="d-block w-100" data-src="holder.js/300x200" alt="holder" />
        </Carousel.Item>}
        {!loading && mediaFiles.map(({id, _links: { download: { href } }, filename}) =>
            <Carousel.Item key={id}>
                <img src={href} alt={filename} className="d-block w-100" style={{height: '30vh', objectFit: 'scale-down'}} />
            </Carousel.Item>)}
    </Carousel>;
}