import React, {useState, useMemo, useCallback, useEffect} from 'react';
import Table from 'react-bootstrap/Table';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {Formik} from "formik";
import {FaSearch, FaPlus, FaEdit, FaTrash, FaQrcode, FaInfo, FaPrint} from 'react-icons/fa';
import logo from '../assets/logos/blue.5.png';
import {Carousel} from "react-bootstrap";

const languages = [
    {code: 'de', name: 'Deutsch', icon: '🇩🇪'},
    {code: 'en', name: 'Englisch', icon: '🇬🇧'},
    {code: 'he', name: 'Hebräisch', icon: '🇮🇱'}
];

const api = process.env.REACT_APP_API || window.location.origin;

function ItemDialog({item, show, onHide}) {
    const [dialogError, setDialogError] = useState(null);
    const hide = () => {
        setDialogError('');
        onHide();
    };

    const [groups, setGroups] = useState([]);
    useEffect(() => {
        (async () => {
            const response = await fetch(`${process.env.REACT_APP_API || window.location.origin}/api/items/search/findByType?type=GROUP`);
            if(response.status !== 200) {
                setDialogError({code: response.status, body: await response.json()});
            } else {
                const {_embedded: {items}} = await response.json();
                setGroups(items);
            }
        })();
    }, [setGroups, show]);

    const [parent, setParent] = useState(null);
    useEffect(() => {
        (async () => {
            if(item.id) {
                const {_links: {parent: {href}}} = item;
                const response = await fetch(href);
                if(response.status === 200) {
                    setParent(await response.json());
                }
            }
        })();
    }, [item, setParent, show]);

    const handleSubmit = async (itemParam) => {
        setDialogError('');
        let id = itemParam.id;
        let item = {
            ...itemParam,
            parent: itemParam.parent === 'none'? '' : itemParam.parent
        };
        if(id) {
            if(item.type === "GROUP")
                item['mediaFiles'] = undefined;
            const response = await fetch(`${api}/api/items/${item.id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(item)
            });
            if(response.status !== 200) {
                setDialogError({code: response.status, body: await response.json()});
            }
            // there's a parent to remove
            if(item.parent === '' && parent) {
                const {_links: {parent: {href: parentLink}}} = item;
                const parentDeleteResponse = await fetch(parentLink, {method: 'DELETE'});
                if(parentDeleteResponse.status !== 204) {
                    setDialogError({code: response.status, body: await response.json()});
                }
            }
        } else {
            const response = await fetch(`${api}/api/items`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(item)
            });
            if(response.status === 201) {
                id = (await response.json()).id;
            } else {
                setDialogError({code: response.status, body: await response.json()});
            }
        }
        if(item.newMediaFiles) {
            for (const file of item.newMediaFiles) {
                const body = new FormData();
                body.append('file', file);
                try {
                     const uploadResponse = await fetch(`${api}/api/items/${id}/mediaFiles`, {
                        method: 'POST',
                        body: body
                    });
                     if(uploadResponse.status !== 200) {
                         setDialogError({ code: uploadResponse.status, body: await uploadResponse.json()});
                     }
                } catch (e) {
                    setDialogError({
                        code: 501,
                        body: {
                            message: `Die Datei ${file} konnte nicht hochgeladen werden. Folgender Fehler ist ` +
                                `aufgetreten: ${e}`
                        }
                    });
                    return;
                }
            }
        }
        if(item.removedFiles) {
            for (const fileId of item.removedFiles) {
                try {
                    const deleteResponse = await fetch(`${api}/api/mediaFiles/${fileId}`, {
                        method: 'DELETE'
                    });
                    if(deleteResponse.status !== 200) {
                        setDialogError({ code: deleteResponse.status, body: await deleteResponse.json()});
                    }
                } catch (e) {
                    setDialogError({
                        code: 501,
                        body: {
                            message: `Eine Datei konnte nicht gelöscht werden. Folgender Fehler ist ` +
                                `aufgetreten: ${e}`
                        }
                    });
                    return;
                }
            }
        }
        hide();
    };
    return <Modal show={show} onHide={hide} size="lg">
        <Modal.Header closeButton>
            <Modal.Title>{item.id? <><FaEdit /> {item.titles['de']}</> : <><FaPlus /> Item</>}</Modal.Title>
        </Modal.Header>
        <Formik initialValues={item} onSubmit={handleSubmit}>
            {({
                  handleSubmit,
                  handleChange,
                  values,
                  setFieldValue
              }) => (
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        {dialogError && <Alert variant="danger">
                            <strong>Fehler!</strong> Es ist ein unerwarteter Fehler aufgetreten (Code: {dialogError.code}).
                            <hr />
                            {dialogError.body.message}
                        </Alert>}
                        <div style={{display: 'flex', justifyContent: 'space-between', columnGap: '5px'}}>
                            <div>
                                <Form.Group className="mb-3" controlId="exhibitionNumber">
                                    <Form.Label>Ausstellungsnummer</Form.Label>
                                    <Form.Control type="text" placeholder="AM..." value={values.exhibitionNumber} onChange={handleChange} />
                                    <Form.Text className="text-muted">
                                        AM7035, AM166, ...
                                    </Form.Text>
                                </Form.Group>
                                <Tabs defaultActiveKey="de" className="mb-3">
                                    {languages.map(({code, name, icon}) => {
                                        return <Tab key={code} title={icon} eventKey={code}>
                                            <Form.Group className="mb-3" controlId={`titles.${code}`}>
                                                <Form.Label>Titel ({name})</Form.Label>
                                                <Form.Control type="text"
                                                              value={values.titles[code] || ''}
                                                              onChange={handleChange}  />
                                                <Form.Text className="text-muted">
                                                    BUCHEINBAND, SEDER-TAFELAUFSATZ, ...
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId={`descriptions.${code}`}>
                                                <Form.Label>Beschreibung ({name})</Form.Label>
                                                <Form.Control as="textarea"
                                                              rows={3}
                                                              value={values.descriptions[code] || ''}
                                                              onChange={handleChange}  />
                                            </Form.Group>
                                        </Tab>;
                                    })}
                                </Tabs>
                                <Form.Group className="mb-3" controlId="orderNumber">
                                    <Form.Label>Reihungsnummer</Form.Label>
                                    <Form.Control type="number" value={values.orderNumber} onChange={handleChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="active">
                                    <Form.Check type="checkbox" label="Aktiv" value={values.active} onChange={handleChange} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="viewOption">
                                    <Form.Select value={values.viewOption} onChange={handleChange}>
                                        <option value="All">Alle</option>
                                        <option value="List">Listenansicht</option>
                                        <option value="Grid">Kachelansicht</option>
                                    </Form.Select>
                                </Form.Group>
                            </div>
                            <div>
                                <Form.Group controlId="type" className="mb-3">
                                    <Form.Label>Typ</Form.Label>
                                    <Form.Check value="GROUP" onChange={handleChange} type="radio" label="Gruppe" checked={values.type === 'GROUP'} />
                                    <Form.Check value="ITEM" onChange={handleChange} type="radio" label="Objekt" checked={values.type === 'ITEM'} />
                                </Form.Group>
                                <Form.Group controlId="parent" className="mb-3">
                                    <Form.Label>Gruppe {values.type !== 'ITEM' && '(nur für Objekte)'}</Form.Label>
                                    <Form.Select value={values.type === 'ITEM'? values.parent || (parent?._links?.self?.href || 'none') : 'none'} onChange={handleChange} disabled={values.type !== 'ITEM'}>
                                        <option value={'none'}>Keine</option>
                                        {groups.filter(({id}) => id !== values.id).map((group) => {
                                            const {exhibitionNumber, titles, _links: {self}} = group;
                                            const title = 'de' in titles? titles['de'] : Object.values(titles)[0];
                                            return <option value={self.href}>({exhibitionNumber}) {title}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId="mediaFile" className="mb-3">
                                    <Form.Label>Bilddatei</Form.Label>
                                    <Form.Control type="file" name="newMediaFiles" multiple disabled={values.type === 'GROUP'} onChange={(event) => setFieldValue('newMediaFiles', event.currentTarget.files)} />
                                    <Form.Text className="text-muted">
                                        (.jpeg, .png), Max: 2MB
                                    </Form.Text>
                                    <br />
                                    {values.type === 'GROUP' && <Form.Text>
                                        Für Gruppen werden die Bilder der beinhalteden Objekte genutzt.
                                    </Form.Text>}
                                </Form.Group>
                                <Carousel style={{ height: '30vh'}}>
                                    {values.mediaFiles.map(({id, _links: { download: { href } }, filename}) =>
                                        <Carousel.Item key={id}>
                                            <img src={href} alt={filename} style={{height: '30vh', objectFit: 'scale-down'}} />
                                            <Carousel.Caption>
                                                <h2>{filename} {values.type !== 'GROUP' && <Button variant="danger" onClick={() => {
                                                    setFieldValue('mediaFiles', values.mediaFiles.filter(({id: mid}) => mid !== id));
                                                    setFieldValue('removedFiles', values.removedFiles? [...values.removedFiles, id] : [id]);
                                                }}><FaTrash /></Button>}</h2>
                                            </Carousel.Caption>
                                        </Carousel.Item>)}
                                </Carousel>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={onHide}>Abbrechen</Button>
                        <Button type="submit" variant="primary">Speichern</Button>
                    </Modal.Footer>
                </Form>
            )}
        </Formik>
    </Modal>;
}

function InfoDialog({show, onHide}) {
    return <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
            <Modal.Title>Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Group controlId="logo" className="mb-3">
                <Form.Label>Logo</Form.Label>
                <Form.Control type="file" name="logo" />
                <Form.Text className="text-muted">
                    (.jpeg, .png), Max: 2MB
                </Form.Text>
                <img src={logo} alt="SAM" />
            </Form.Group>
        </Modal.Body>
    </Modal>;
}

function Admin() {
    const emptyItem = {exhibitionNumber: '', titles: {}, descriptions: {}, orderNumber: 0, active: true, viewOption: 'All', mediaFiles: [], type: 'ITEM'};
    const [items, setItems] = useState([]);
    const [error, setError] = useState(null);
    const [filteredItems, setFilteredItems] = useState([]);
    const [search, setSearch] = useState("");
    const [showItemDialog, setShowItemDialog] = useState(false);
    const [dialogItem, setDialogItem] = useState(emptyItem);
    const [showInfoDialog, setShowInfoDialog] = useState(false);
    const [selectAll, setSelectAll] = useState(false);
    const [selected, setSelected] = useState([]);
    const refreshItems = useCallback(async () => {
        const response = await fetch(`${api}/api/items/search/findAllOrderByOrderNumber`);
        if(response.status === 200) {
            const {_embedded: {items}} = await response.json();
            setItems(items);
            setFilteredItems(items);
        } else {
            setError({code: response.status, message: await response.text()});
        }
    }, [setItems, setFilteredItems, setError]);
    const printItems = async () => {
        const ids = selectAll? items.map(({id}) => id) : selected;
        const response = await fetch(`${api}/api/reports`, {
            method: 'POST',
            body: JSON.stringify(ids),
            headers: {
                "Content-Type": "application/json"
            }
        });
        if(response.status !== 200) {
            window.alert(`Die Datei konnte nicht erstellt werden! Error Code: ${response.status}`);
            return;
        }
        const url = window.URL.createObjectURL(await response.blob());
        const a = document.createElement('a');
        a.href = url;
        a.download = 'SAM-Export.docx';
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
    };
    useMemo(async () => {
        await refreshItems();
    }, [refreshItems]);
    const deleteItem = useCallback(async (item) => {
        if(window.confirm(`Wollen Sie den Eintrag ${item.exhibitionNumber} wirklich löschen?`)) {
            await fetch(`${api}/api/items/${item.id}`, {
                method: 'DELETE'
            });
            await refreshItems();
        }
    }, [refreshItems])
    const searchItems = (search) => {
        const terms = search.split(' ');
        setFilteredItems(items.filter(item => {
            const {exhibitionNumber, titles, descriptions} = item;
            return terms.every(term => {
                return exhibitionNumber.includes(term)
                    || Object.values(titles).find(title => title.includes(term))
                    || Object.values(descriptions).find(description => description.includes(term));
            });
        }));
    };
    const openNewItemDialog = () => {
        setDialogItem(emptyItem);
        setShowItemDialog(true);
    };
    const openEditItemDialog = (item) => {
        setDialogItem(item);
        setShowItemDialog(true);
    };
    return <main style={{width: 'calc(100vw - 50px)', alignItems: 'center', paddingTop: '1rem', marginLeft: '25px', marginRight: '25px'}}>
        <ItemDialog item={dialogItem} show={showItemDialog} onHide={async () => {
            setShowItemDialog(false);
            await refreshItems();
        }} />
        <InfoDialog show={showInfoDialog} onHide={() => setShowInfoDialog(false)} />
        <h2>Administration</h2>
        {error && <Alert key={error.code} variant="danger">
            <strong>Fehler!</strong> Es ist ein unerwarteter Fehler aufgetreten (Code: {error.code}).
            <code>
                {error.message}
            </code>
        </Alert>}
        <Button style={{margin: '0.25rem'}} variant="success" onClick={() => openNewItemDialog()}><FaPlus /></Button>
        <Button style={{margin: '0.25rem'}} variant="primary" onClick={() => setShowInfoDialog(true)}><FaInfo /></Button>
        <Button style={{margin: '0.25rem'}} variant="primary" onClick={() => printItems()}><FaPrint /></Button>
        <InputGroup className="mb-3">
        </InputGroup>
        <InputGroup className="mb-3">
            <Form.Control value={search} onChange={({target: {value}}) => {
                setSearch(value);
                searchItems(value);
            }} />
            <Button variant="primary" onClick={() => searchItems(search)}>
                <FaSearch />
            </Button>
        </InputGroup>
        <Table striped bordered hover>
            <thead>
                <tr>
                    <th>#</th>
                    <th>Typ</th>
                    <th>Name</th>
                    <th>Beschreibung</th>
                    <th>Sprachen</th>
                    <th>Reihungsnummer</th>
                    <th>Aktiv</th>
                    <th>Aktionen</th>
                    <th><input type="checkbox" checked={selectAll} onChange={(e) => setSelectAll(e.currentTarget.checked)} /></th>
                </tr>
            </thead>
            <tbody>
            {filteredItems.map(item => {
                return <tr key={item.id}>
                    <td>{item.exhibitionNumber}</td>
                    <td>{item.type === 'GROUP' && 'Gruppe'} {item.type === 'ITEM' && 'Object'}</td>
                    <td>{item.titles['de']}</td>
                    <td>{(item.descriptions['de'] || '').substr(0, 100)}...</td>
                    <td>{item.supportedLanguages.map(lang => languages.find(({code}) => code === lang).icon)}</td>
                    <td>{item.orderNumber}</td>
                    <td><input type="checkbox" checked={item.active} readOnly /></td>
                    <td style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Button variant="dark" size="sm" onClick={() => openEditItemDialog(item)}><FaEdit /></Button>
                        <Button variant="dark" size="sm" onClick={() => window.open(`${api}/api/items/${item.id}/qr.png`).focus()}><FaQrcode /></Button>
                        <Button variant="danger" size="sm" onClick={() => deleteItem(item)}><FaTrash /></Button>
                    </td>
                    <td><input type="checkbox" checked={selectAll || item.selected} onChange={(e) => {
                        if(!e.currentTarget.checked) {
                            setSelectAll(false);
                            setSelected(selected.filter(id => id !== item.id));
                        } else {
                            setSelected([...selected, item.id]);
                        }
                    }} /></td>
                </tr>;
            })}
            </tbody>
        </Table>
    </main>;
}

export default Admin;