import {Routes, Route} from "react-router-dom";
import {Client} from 'ketting';
import {KettingProvider} from 'react-ketting';
import Admin from "./sites/Admin";
import ItemView from "./sites/ItemView";
import './App.css';
import ItemsList from "./sites/ItemsList";

const client = new Client(process.env.REACT_APP_API || window.location.origin);

function App() {
    return (
        <div className="App">
            <KettingProvider client={client}>
                <Routes>
                    <Route path="/" element={<ItemsList/>}/>
                    <Route path="/item/:id" element={<ItemView />}/>
                    <Route path="/admin" element={<Admin/>}/>
                </Routes>
            </KettingProvider>
        </div>
    );
}

export default App;
