import React, {useMemo, useState} from 'react';
import {ToggleButton, ToggleButtonGroup} from "react-bootstrap";

export default function ActionBar({language, setLanguage}) {

    const [languages, setLanguages] = useState([]);
    useMemo(async () => {
        const response = await fetch(`${process.env.REACT_APP_API || window.location.origin}/api/supportedLanguages`);
        if(response.status === 200) {
            setLanguages(await response.json());
        }
    }, [setLanguages]);

    return <ToggleButtonGroup type="radio" name="language" value={language} onChange={setLanguage}>
        {languages && languages.length > 1 && languages.map(({code, emoji}) =>
            <ToggleButton key={code} id={code} value={code}>{emoji}</ToggleButton>)}
    </ToggleButtonGroup>;
}