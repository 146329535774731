import React, {useEffect, useState} from 'react';
import {useResource, useClient} from 'react-ketting';
import {useParams} from "react-router-dom";
import {Card, Placeholder, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import {useSwipeable} from "react-swipeable";
import MediaFileCarousel from "../components/MediaFileCarousel";
import ActionBar from "../components/ActionBar";
import {useNavigate} from "react-router-dom";
import {BsCardText, BsFillGridFill} from "react-icons/bs";

function ItemView() {

    const {id = 'search/findFirst'} = useParams() || {};

    const client = useClient();
    const [itemResource, setItemResource] = useState(client.go(`/api/items/${id}`));
    const {loading, data: item, resourceState} = useResource(itemResource);

    const [parentResource, setParentResource] = useState(itemResource.follow('parent'));
    const {data: parent} = useResource(parentResource);

    useEffect(() => {
        setItemResource(client.go(`/api/items/${id}`));
    }, [id, client]);

    useEffect(() => {
        setParentResource(itemResource.follow('parent'));
    }, [itemResource]);

    const [children, setChildren] = useState(null);
    useEffect(() => {
        (async () => {
            if (id !== 'search/findFirst') {
                const cid = parent? parent.id : item.id;
                const response = await fetch(`${process.env.REACT_APP_API || window.location.origin}/api/items/${cid}/children`);
                if (response.status === 200) {
                    const body = await response.json();
                    setChildren(body._embedded.items);
                }
            }
        })();
    }, [id, item, parent, setChildren]);

    const movePrevious = () => setItemResource(itemResource.follow('previous'));
    const hasPrevious = () => resourceState.links.has('previous');

    const moveNext = () => setItemResource(itemResource.follow('next'));
    const hasNext = () => resourceState.links.has('next');

    const handlers = useSwipeable({onSwipedLeft: movePrevious, onSwipedRight: moveNext});

    const [language, setLanguage] = useState('de');

    const ArrowButton = ({
                             direction = 'right', disabled = false, onClick = () => {
        }
                         }) => {
        return <button disabled={disabled}
                       onClick={onClick}
                       style={{
                           background: 'none',
                           border: 'none',
                           color: '#D4AF37',
                           fontWeight: 'bold',
                           fontSize: '16pt'
                       }}>
            {direction === 'right' ? '>' : '<'}
        </button>;
    };

    const Separator = () => <hr style={{flexGrow: '1', border: '1px solid #D4AF37', opacity: 1}}/>;
    const nav = useNavigate();
    return <main {...handlers} style={{color: '#FFFAE5', fontFamily: "'Roboto',Helvetica,Arial,Lucida,sans-serif"}}>
        <header style={{display: 'flex', justifyContent: 'center', padding: '0.5rem'}}>
            <a href="/#/"><img src="/logo_blue_5.png" alt="SAM"/></a>
        </header>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '5px'}}>
            <ActionBar language={language} setLanguage={setLanguage}/>
            <ToggleButtonGroup type="radio" name="displayMode" onChange={value => {
                nav(`/?v=${value}`);
            }} style={{margin: '0.25rem'}}>
                <ToggleButton id="list" value="list"><BsCardText/></ToggleButton>
                <ToggleButton id="grid" value="grid"><BsFillGridFill/></ToggleButton>
            </ToggleButtonGroup>
        </div>
        {item && <article>
            <MediaFileCarousel mediaFiles={item.mediaFiles} loading={loading}/>
            <Card style={{background: '#003984', margin: '0.5rem', border: '0px'}}>
                <Card.Header style={{background: 'white'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <ArrowButton direction="left" disabled={!hasPrevious()} onClick={movePrevious}/>
                        <ArrowButton direction="right" disabled={!hasNext()} onClick={moveNext}/>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Separator/>
                    {!loading && <Card.Title>
                        <p style={{
                            flexGrow: '1',
                            textAlign: 'center'
                        }}>{item.titles[language]} ({item.exhibitionNumber})</p>
                    </Card.Title>}
                    {parent && <>
                        <Card.Subtitle>
                            <small>
                                <strong>Gehört zu:</strong>
                                <button style={{
                                    background: 'transparent',
                                    border: 'none',
                                    color: 'white',
                                    textDecoration: 'underline'
                                }}
                                        onClick={() => setItemResource(itemResource.follow('parent'))}>
                                    {parent.titles[language]} ({parent.exhibitionNumber})
                                </button>
                            </small>
                        </Card.Subtitle>
                    </>}
                    <Separator/>
                    {!loading && <Card.Text style={{whiteSpace: 'pre-line'}}>{item.descriptions[language]}</Card.Text>}
                    {loading && <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6}/>
                    </Placeholder>}
                    {loading && <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7}/> <Placeholder xs={4}/> <Placeholder xs={4}/>{' '}
                        <Placeholder xs={6}/> <Placeholder xs={8}/>
                    </Placeholder>}
                    {children && <>
                        <Separator/>
                        <Card.Text style={{whiteSpace: 'pre-line'}}>
                            <ul>
                                {children.sort(({orderNumber: a}, {orderNumber: b}) => a -b).map(({id, titles, exhibitionNumber}) => {
                                    const title = titles[language];
                                    return <li><a href={`/#/item/${id}`}
                                                  style={{color: 'white'}}>{title} ({exhibitionNumber})</a></li>
                                })}
                            </ul>
                        </Card.Text>
                    </>}
                </Card.Body>
            </Card>
        </article>}
    </main>;
}

export default ItemView;